import request from "@/utils/request";

// 新增公告
export function noticeAdd(data) {
  return request({
    url: "/brand/system/notice/add",
    method: "POST",
    data: data,
  });
}

// 删除公告
export function noticeDelete(data) {
  return request({
    url: "/brand/system/notice/delete",
    method: "POST",
    data: data,
  });
}

// 公告详情
export function noticeInfo(data) {
  return request({
    url: "/brand/system/notice/info",
    method: "POST",
    data: data,
  });
}

// 公告列表
export function getNoticeList(data) {
  return request({
    url: "/brand/system/notice/list",
    method: "POST",
    data: data,
  });
}

// 公告设置
export function noticeSetting(data) {
  return request({
    url: "/brand/system/notice/setting",
    method: "POST",
    data: data,
  });
}

// 编辑公告
export function noticeUpdate(data) {
  return request({
    url: "/brand/system/notice/update",
    method: "POST",
    data: data,
  });
}

// 新增消息类型
export function noticeTypeAdd(data) {
  return request({
    url: "/brand/system/noticeType/add",
    method: "POST",
    data: data,
  });
}

// 消息类型列表
export function noticeTypeList(data) {
  return request({
    url: "/brand/system/noticeType/list",
    method: "POST",
    data: data,
  });
}

// 获得上传文件
export function getFileUploadToken(data) {
  return request({
    url: "/brand/file/getFileUploadToken",
    method: "POST",
    data: data,
  });
}

// 获得文件链接
export function getFileUrl(data) {
  return request({
    url: "/brand/file/getFileUrl",
    method: "POST",
    data: data,
  });
}
