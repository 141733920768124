<template>
  <div>
    <!-- <el-upload
      :action="uploadUrl"
      :before-upload="handleBeforeUpload"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      name="file"
      :show-file-list="false"
      :headers="headers"
      class="editor-img-uploader"
      v-if="type == 'url'"
    >
      <i ref="uploadRef" class="editor-img-uploader"></i>
    </el-upload> -->
    <el-upload
      :data="uploadParams"
      :action="uploadUrl"
      :before-upload="handleFileChange"
      :on-success="handleSuccess"
      name="file"
      :show-file-list="false"
      class="editor-img-uploader"
      v-if="type == 'url'"
    >
      <i ref="uploadRef" class="editor-img-uploader"></i>
    </el-upload>
  </div>
  <div class="editor">
    <quill-editor
      ref="quillEditorRef"
      v-model:content="content"
      contentType="html"
      @textChange="(e) => $emit('update:modelValue', content)"
      :options="options"
      :style="styles"
    />
  </div>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { getToken } from "@/utils/auth";
import { getFileUploadToken, getFileUrl } from "@/api/tenant/notice";


import * as Quill from 'quill'
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
// 设置字体大小
const fontSizeStyle = Quill.import('attributors/style/size') // 引入这个后会把样式写在style上
fontSizeStyle.whitelist = ['12px', '14px','28px','36px']
Quill.register(fontSizeStyle, true)
// // 设置字体样式
// const Font = Quill.import('attributors/style/font') // 引入这个后会把样式写在style上
// const fonts = [
//     'SimSun',
//     'SimHei',
//     'Microsoft-YaHei',
//     'KaiTi',
//     'FangSong'
// ]
// Font.whitelist = fonts // 将字体加入到白名单
// Quill.register(Font, true)
// // 设置文字水平方式
// var Align = Quill.import('attributors/style/align')
// Align.whitelist = ['right', 'center', 'justify']
// Quill.register(Align, true)


const { proxy } = getCurrentInstance();

const quillEditorRef = ref();
// const uploadUrl = ref(import.meta.env.VITE_APP_BASE_API + "/common/upload"); // 上传的图片服务器地址
const uploadUrl = ref("");

const headers = ref({
  Authorization: "Bearer " + getToken(),
});
const uploadParams = ref({});

const props = defineProps({
  /* 编辑器的内容 */
  modelValue: {
    type: String,
  },
  /* 高度 */
  height: {
    type: Number,
    default: null,
  },
  /* 最小高度 */
  minHeight: {
    type: Number,
    default: null,
  },
  /* 只读 */
  readOnly: {
    type: Boolean,
    default: false,
  },
  /* 上传文件大小限制(MB) */
  fileSize: {
    type: Number,
    default: 5,
  },
  /* 类型（base64格式、url格式） */
  type: {
    type: String,
    default: "url",
  },
});

const options = ref({
  theme: "snow",
  bounds: document.body,
  debug: "warn",
  modules: {
    // 工具栏配置
    toolbar: [
      ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
      ["blockquote", "code-block"], // 引用  代码块
      [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
      [{ indent: "-1" }, { indent: "+1" }], // 缩进
      // [{ size: ["small", false, "large", "huge"] }], // 字体大小
      [{ size: fontSizeStyle.whitelist }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
      [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
      [{ align: [] }], // 对齐方式
      ["clean"], // 清除文本格式
      ["image"], // 链接、图片、视频
    ],
  },
  placeholder: "请输入内容",
  readOnly: props.readOnly,
});

const styles = computed(() => {
  let style = {};
  if (props.minHeight) {
    style.minHeight = `${props.minHeight}px`;
  }
  if (props.height) {
    style.height = `${props.height}px`;
  }
  return style;
});

const content = ref("");
watch(
  () => props.modelValue,
  (v) => {
    if (v !== content.value) {
      content.value = v === undefined ? "<p></p>" : v;
    }
  },
  { immediate: true }
);

// 如果设置了上传地址则自定义图片上传事件
onMounted(() => {
  if (props.type == "url") {
    let quill = quillEditorRef.value.getQuill();
    let toolbar = quill.getModule("toolbar");
    toolbar.addHandler("image", (value) => {
      if (value) {
        proxy.$refs.uploadRef.click();
      } else {
        quill.format("image", false);
      }
    });
  }
});

// 上传前校检格式和大小
function handleBeforeUpload(file) {
  console.log("askldjlskjdljasdkljlaskjd");

  const type = ["image/jpeg", "image/jpg", "image/png", "image/svg"];
  const isJPG = type.includes(file.type);
  //检验文件格式
  if (!isJPG) {
    proxy.$modal.msgError(`图片格式错误!`);
    return false;
  }
  // 校检文件大小
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize;
    if (!isLt) {
      proxy.$modal.msgError(`上传文件大小不能超过 ${props.fileSize} MB!`);
      return false;
    }
  }
  return true;
}

// 上传成功处理
function handleUploadSuccess(res, file) {
  // 如果上传成功
  if (res.code == 200) {
    // 获取富文本实例
    let quill = toRaw(quillEditorRef.value).getQuill();
    // 获取光标位置
    let length = quill.selection.savedRange.index;
    // 插入图片，res.url为服务器返回的图片链接地址
    quill.insertEmbed(
      length,
      "image",
      import.meta.env.VITE_APP_BASE_API + res.fileName
    );
    // 调整光标到最后
    quill.setSelection(length + 1);
  } else {
    proxy.$modal.msgError("图片插入失败");
  }
}

// 上传失败处理
function handleUploadError() {
  proxy.$modal.msgError("图片插入失败");
}
// --------------------------------------
const handleFileChange = async (file) => {
  // console.log("handleFileChangessss", file);
  if (file) {
    const isValid = beforeUpload(file);
    if (!isValid) return false;
    try {
      const res = await getFileUploadToken({
        biz: 1545,
        filename: file.name,
        filesize: file.size,
      });
      console.log(res.data, "res.datares.datares.data");

      uploadUrl.value = res.data.uploadUrl;
      uploadParams.value = res.data.multipartParams;

      // nextTick(() => {
      //   uploadRef.value.submit();
      // });
    } catch (error) {
      ElMessage.error("获取上传地址失败");
      return false;
    }
  }
};
const beforeUpload = (file) => {
  // console.log("beforeUpload", file);

  const isJPGorPNG = file.type === "image/jpeg" || file.type === "image/png";
  const isLt2M = file.size / 1024 / 1024 < 2;
  const fileNameWithoutExtension = file.name.split(".").slice(0, -1).join(".");
  if (!fileNameWithoutExtension) {
    ElMessage.error("文件名（无扩展名）不能为空");
    return false;
  }
  if (!isJPGorPNG) {
    ElMessage.error("只支持 JPEG 和 PNG 格式!");
    return false;
  }
  if (!isLt2M) {
    ElMessage.error("图片大小不能超过 2MB!");
    return false;
  }
  return true;
};
const handleSuccess = (response, file) => {
  console.log(response, "handleSuccess-response");
  console.log(file, "handleSuccess-response");
  getFileUrl({ token: response.token }).then((res) => {
    console.log(res, "第三步");

    // 如果上传成功
    if (res.code == 0) {
      // 获取富文本实例
      let quill = toRaw(quillEditorRef.value).getQuill();
      // 获取光标位置
      let length = quill.selection.savedRange.index;
      // 插入图片，res.url为服务器返回的图片链接地址
      quill.insertEmbed(length, "image", res.data.url);
      // 调整光标到最后
      quill.setSelection(length + 1);
    } else {
      proxy.$modal.msgError("图片插入失败");
    }
  });

  // imageUrl.value = URL.createObjectURL(file.raw);
  // isUploaded.value = true;

  // if (response.token) {
  //   emit("uploadSuccess", response.token);
  // }
};
</script>

<style>
.editor-img-uploader {
  display: none;
}
.editor,
.ql-toolbar {
  white-space: pre-wrap !important;
  line-height: normal !important;
}
.quill-img {
  display: none;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}
.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}


.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36px";
}

</style>
